/* You can add global styles to this file, and also import other style files */
/* styles.css or styles.scss */
@import '~@fortawesome/fontawesome-free/css/all.css';

@import '../src/styles/material3.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
}
#backdrop {
  display: block;
}
.error {
  color: #AF1C3D;
}
.close-btn{
  height: 24px;
  width: 24px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.disabled {
  color: #DEE2E6;
  cursor: not-allowed;
  pointer-events: none;
}

.cust-outline-on-focus{
  --mdc-outlined-text-field-focus-outline-color: #1fc6a7;
  margin-bottom: -1.25em;
}

input.e-input::selection, .e-input-group input.e-input::selection, .e-input-group.e-control-wrapper input.e-input::selection, .e-float-input input::selection, .e-float-input.e-control-wrapper input::selection{
  background: #1dcbac !important;
  color: #fff;
}

input[type="number"] {
  -webkit-appearance: none; /* Remove default Safari styling */
}

.cust-mat-cal {
  height: 26px !important;
  font-size: 14px !important;
  --mat-datepicker-calendar-date-selected-state-background-color: #5b987c;
  --mdc-icon-button-icon-size: 20px;

  .mat-mdc-text-field-wrapper {
    height: 26px !important;
  }
  .mat-mdc-form-field-flex {
    height: 26px !important;
  }
  .mat-mdc-form-field-infix {
    padding: 0px !important;
  }
}
.mat-calendar-body-selected {
  background-color: #5b987c !important;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(91, 152, 124, 0.3) !important;
}
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(91, 152, 124, 0.3) !important;
}
.mat-datepicker-toggle-active {
  color: #24b89b !important;
}

.cust-mat-cal input {
  height: 26px !important;
}


.cust-mat-cal.mat-focused .mat-form-field-outline {
  border-color: #51b53f; /* Replace with your desired color */
}

.cust-mat-cal.mat-focused .mat-form-field-outline-thick {
  border-color: #3fb56a; /* Replace with your desired color */
}

.cust-mat-cal .mat-mdc-form-field-infix {
  min-height: 26px !important;
}
.mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix{
  padding: 6px 0 !important;
}
.mdc-text-field--outlined {
  height: 36px !important;
}
.cust-outline-on-focus {
  margin-bottom: 0;
}
.mat-mdc-form-field-flex{
  height: 36px !important;
}
.mat-mdc-form-field-infix{
  min-height: 36px !important;
}
.mdc-text-field--outlined .mdc-notched-outline__trailing{
  border-color: #666666 !important;
}
.mdc-text-field--outlined .mdc-notched-outline__leading{
  border-color: #666666 !important;
}
.mdc-notched-outline{
  outline: none !important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input
{
  color: #000000 !important;
}
